import React from "react";
import { Accordion, StatusCallout } from "@wisr/react-ui";
import { CreditProfileFormComponent } from "../credit-profile-form";
import { StreetType } from "../../../../generated/graphql-types";
import { useGetUser } from "../../../user/user.hooks";
import { useIdChecksSuccessful } from "../../../widgets/credit/score/score-credit-widget.hooks";
import style from "./review-information-form.scss";
import { isBrowserMobile } from "../../../shared/browser/browser.utils";
import {
  LOAN_CALLOUT,
  REVIEW_INFORMATION,
  STILL_HAVING_ISSUES,
} from "./review-information.constants";

interface ReviewInformation {
  streetTypes: StreetType[];
}

export const ReviewInformationForm: React.FC<ReviewInformation> = ({
  streetTypes,
}) => {
  const user = useGetUser();
  const idmResult = useIdChecksSuccessful();
  const isMobile = isBrowserMobile();

  return (
    <div className="wrapper">
      <style jsx>{style}</style>
      <h2>Review my information</h2>
      <Accordion
        title="Not seeing your scores?"
        startOpen={true}
        alignArrow={isMobile ? "right" : "left"}
      >
        {REVIEW_INFORMATION}
        {isMobile && STILL_HAVING_ISSUES}
      </Accordion>
      <div className="form">
        {user.loanCount > 0 ? (
          <div className="status">
            <StatusCallout
              type="info"
              message={LOAN_CALLOUT}
              theme={"outline"}
            />
          </div>
        ) : (
          <div className="spacing" />
        )}
        <CreditProfileFormComponent
          existingUser
          successSubmitMsg="Profile has been updated successfully!"
          streetTypes={streetTypes}
          disabled={idmResult}
        />
      </div>
    </div>
  );
};
