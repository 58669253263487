import { isBrowser } from "@wisr/common";
import { Card } from "@wisr/react-ui";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { ReviewInformationForm } from "../../forms/credit-profile/review-information/review-information-form";
import { STILL_HAVING_ISSUES } from "../../forms/credit-profile/review-information/review-information.constants";
import { PageWrapperComponent } from "../../layout/page-wrapper/page-wrapper-layout.component";
import { analyticsViewPageEvent } from "../../shared/analytics/analytics.utils";
import { isBrowserMobile } from "../../shared/browser/browser.utils";
import { ErrorWidgetComponent } from "../../widgets/shared/error/error-widget.component";
import style from "./credit-profile.page.scss";

export const CreditProfilePage = () => {
  const isMobile = isBrowserMobile();
  const { formValues } = useStaticQuery(graphql`
    query MyAccountPage {
      formValues {
        streetTypes {
          description
          common
          code
        }
      }
    }
  `);

  if (!formValues) {
    return <ErrorWidgetComponent />;
  }

  const { streetTypes } = formValues;

  if (isBrowser()) {
    analyticsViewPageEvent("My account");
  } else {
    return null;
  }

  return (
    <PageWrapperComponent size="half">
      <style jsx>{style}</style>
      <div className="prefix">
        {isMobile ? (
          <ReviewInformationForm streetTypes={streetTypes} />
        ) : (
          <Card>
            <ReviewInformationForm streetTypes={streetTypes} />
          </Card>
        )}
      </div>
      {!isMobile && (
        <div className="suffix">
          <Card>{STILL_HAVING_ISSUES}</Card>
        </div>
      )}
    </PageWrapperComponent>
  );
};
