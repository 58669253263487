import React from "react";
import {
  EQUIFAX_CORRECTIONS,
  EXPERIAN_CORRECTIONS,
} from "../../../shared/url.consts";

export const REVIEW_INFORMATION: React.ReactElement = (
  <>
    <p>
      The information you provided when checking your Credit Scores through Wisr
      may not match up with the information that the credit bureaus have on
      file.
    </p>
    <p>Double-check that your details are correct.</p>
    <ul>
      <li>Is your name spelt correctly?</li>
      <li>Is your date of birth correct?</li>
      <li>
        Have you moved house recently? The credit bureau may have your previous
        address on file. Try using that one.
      </li>
      <li>
        Have you renewed your licence, passport or Medicare card recently? The
        credit bureau may not have received your updated one. Try punching in
        your old digits.
      </li>
      <li>
        You can also try a different identity document from our list of Drivers
        Licence, Passport or Medicare card.
      </li>
    </ul>
  </>
);

export const STILL_HAVING_ISSUES: React.ReactElement = (
  <>
    <h3>Still having issues?</h3>
    <p>
      To update or correct information on your credit reports, you need to
      contact{" "}
      <a href={EQUIFAX_CORRECTIONS} target="_blank" rel="noopener nofollow">
        Equifax
      </a>{" "}
      or{" "}
      <a href={EXPERIAN_CORRECTIONS} target="_blank" rel="noopener nofollow">
        Experian
      </a>{" "}
      directly and go through the formal corrections process.
    </p>
  </>
);

export const LOAN_CALLOUT: React.ReactElement = (
  <>
    <p>
      Loan Customers: This only edits your details to get your Credit Score and{" "}
      <strong>does not</strong> change the way we&apos;ll contact you about your
      loan.
    </p>
  </>
);
